#word-wrap-clone-and-maint-checkbox {
  white-space: wrap;
}

.clone-and-maint-checkbox {
  margin-left: 10px;
}

#clone-and-maint-checkbox.form-check-input{
  margin-top: 15px;
}