.layout{
  z-index: 1
}

.canvas{
  position: absolute ;
  /* overflow-x: scroll; */
  z-index:-100;
}

.rotate-text {
  vertical-align: middle;
  text-align: center;
  -ms-writing-mode: tb-rl;
  -webkit-writing-mode: vertical-rl;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  white-space: nowrap;
}
.special{
  display: flex;
}

.lanes{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 63px;
  position: sticky;
  left: 0px;
  right: 0px;
  z-index: 1000;
}

.lanes > span{
  display: block;
  height: 99px;
  color: white;
}

#PROD-lane {
  background-color: rgb(0, 83, 4);
}
#STAGE-lane {
  background-color: rgb(0, 156, 8);
}
#UAT-lane {
  background-color: rgb(0, 1, 80);
}
#DEV-lane {
  background-color: rgb(0, 110, 255);
}


#PROD, #PROD-1 ,#PROD-2,
#STAGE, #STAGE-1, #STAGE-2,
#UAT, #UAT-1, #UAT-2,
#DEV, #DEV-1, #DEV-2 {
  padding: 0;
  font-size: 0px;
  max-width: 0px;
  border: none !important;
}

td[name=PROD], [name=STAGE], [name=UAT], [name=DEV]{
  border-bottom: none !important
}

td[name=PROD-1], [name=STAGE-1], [name=UAT-1], [name=DEV-1]{
  border: none !important
}

td[name=PROD-2], [name=STAGE-2], [name=UAT-2], [name=DEV-2]{
  border-top: none !important
}

.toolTip {
  display: none;
  position: absolute;
  z-index: 100;
  background-color: white;
}

.parentCell:hover span.toolTip{
  display: block;
}


#Swimlane-weeks {
  padding: 0 0 0 0;
  background-color: white;
  color: black;
  max-width: 119px !important;
  min-width: 119px !important;
  border: none !important;

}

#days {
  height: 33px !important;
  width: 17px !important;
  padding: 8px !important;
  border-right: 1px dotted black !important;
  border-left: 1px dotted black !important;
}

#days:nth-child(7n+1) {
  /* background-color: red; */
  border-right: 1px solid black !important;
}

#days:nth-child(-n+2) {
  border-left: 1px solid black !important
}


#Swimlane-days {
  font-size: 0px;
  padding: 0 0 0 0;
  border: none !important;
}

tr:nth-child(3n){
  border-bottom: 1px solid black !important;
}


td {
  border: 1px solid black !important;
  height: 100px !important;
}

th {
  text-align: center !important;
}
