.link {
  font-size: 1.5em;
}

@media (min-width: 100vw ) {
  .container { 
    max-width: 100vw !important;
    margin-right: auto;
  }
}

#profile {
  margin-right: 1em;
  margin-left: 1em;
  height: 71px;
  border-left: 1px solid white;
}

[data-letters]:before {
  content:attr(data-letters);
  display:inline-block;
  font-size:1em;
  width:2.5em;
  height:2.5em;
  line-height:2.5em;
  text-align:center;
  border-radius:50%;
  background:rgba(0, 200, 240);
  vertical-align:middle;
  color:white;
  }

 .tooltip-inner {
    max-width: fit-content !important;
    text-align: left !important;
  }

  .navbar {
    background-color:  rgb(0, 68, 106);
    color: white;
    width: 100%;
    border: thin solid black;
    border-top: none;
  }
  
  .navbar >* a{
    color: white !important;
  }