th {
  background-color: grey;
  color: white;
  border: 0px solid black !important;
  white-space: nowrap;
  padding: 0 1vw 0 1vw
}

td {
  text-align: center;
  white-space: nowrap;
  height: auto !important;
  padding: 5px;
}

#buttons {
  background: white;
  border: none !important;
}
