body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0 2vw 10vh 2vw;;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

a:link {
  text-decoration: none;
}

.height600 {
  height: 600px;
}

.nav-bumper{
  height: 19.8px;
}

.section-wrapper {
  margin-top: 5vh;
  border: medium solid black;
}

#sections-overview-flavor-text{
  font-size:small;
}

h1 {
  border-bottom: medium solid black;
  background-color:  rgb(0, 68, 106);
  color: white;
}

.scroll-table-x {
  position: relative;
  overflow-x: scroll;
  z-index: 10;
}

#env-checkboxes {
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
  margin: 0 1vw 0 1vw;
}

.modal-width {
  width: 35%!important;
  max-width: none!important;
}

.modal-width-update {
  max-width: none!important;
}

td {
  border: 1px solid black;
}
