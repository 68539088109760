#sections-overview-bg {
    display: flex;
    background-image: url("../../assets/headerImage.jpg");
    background-size: cover;
    justify-content: flex-end;
}

#card-wrapper >* .non-bg-card {
    max-width: 7.685vw;
} 

.card-img {
    height: 7.455vh
}

.card-text { 
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: calc(.3em + .6vw);
    height: 7.45vh;
    text-align: center;
}

.sticky-also {
    position: sticky;
}